/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react';
import Slider from 'react-slick';
import data from '../../data/myprojects.json';
import './MyProjects.css';
import MyProjectCard from './MyProjectCard';
import MyProjectModal from './MyProjectModal';

function CustomNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: '#667eea' }}
      onClick={onClick}
    />
  );
}

function CustomePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: '#667eea' }}
      onClick={onClick}
    />
  );
}

const MyProjects = () => {
  const [showModal, setShowModal] = useState(false);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="portfolio--section" id="MyPortfolio">
        <div className="portfolio--container-box">
          <div className="portfolio--container">
            <h2 className="section--heading">My Projects</h2>
          </div>
        </div>
        <div className="mt-10">
          <Slider {...settings}>
            {data?.myprojects?.map((projectData) => (
              <MyProjectCard
                key={projectData.id}
                myproject={projectData}
                onOpen={() => setShowModal(projectData)}
              />
            ))}
          </Slider>

        </div>
      </section>
      {
            showModal && (
            <MyProjectModal onClose={() => setShowModal(null)} modalData={showModal} />
            )
        }
    </>
  );
};

export default MyProjects;
