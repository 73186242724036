/* eslint-disable react/button-has-type */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import { PiBookOpenTextLight } from 'react-icons/pi';

const MyProjectCard = ({ myproject, onOpen }) => (
  <div className="z-1 bg-white h-[450px] text-black rounded-xl">
    <div className="h-56 rounded-t-xl bg-indigo-500 flex justify-center items-center">
      <img src={myproject.src} alt="Project" className="h-44 w-44 rounded-full" />
    </div>

    <div className="flex flex-col rounded-b-xl bg-gray-100 justify-center items-center gap-4 p-4">
      <p className="text-xl font-semibold">{myproject.title}</p>
      <div className="">
        <div className="flex">
          <PiBookOpenTextLight className="text-red-300 text-2xl" />
          <h4 className="mx-2">{myproject.type}</h4>
        </div>
      </div>
      <button onClick={onOpen} className="bg-indigo-500 text-white text-lg px-6 py-1 rounded-xl">View Details</button>
    </div>
  </div>
);

export default MyProjectCard;
